// @flow
/* global document, window */

import noScroll from 'no-scroll';
import activeElement from 'dom-helpers/activeElement';
import filterEventHandler from 'dom-helpers/filterEventHandler';
import height from 'dom-helpers/height';
import removeClass from 'dom-helpers/removeClass';
import scrollTop from 'dom-helpers/scrollTop';
import width from 'dom-helpers/width';

import throttle from '../../main/throttle';
import compat from '../utils/compat';
import { parseHtml, toPx } from '../utils/dom';
import { scrollToPosition } from '../utils/scrolling';
import Transition from '../utils/transition';
import Iframe from './iframe';

type ModalProps = {
  src: string,
  minHeight?: number,
};

function getVerticalMargin() {
  return width(window) < 600 ? 36 : 48;
}

/**
 * For most browsers (based on support of iframe overflow scrolling), we:
 *
 * - use "fixed" positioning
 * - lock the page scroll
 * - set the modal height to the content height, but not larger than the window height
 * - allowing scrolling of the iframe's containing element if clipped by window height
 */
class ModalScrollingLayoutManager {
  constructor(instance) {
    this.instance = instance;
    this._contentHeight = 0;
  }

  init() {
    this.instance.modal.style.position = 'fixed';
  }

  onOpen() {
    this.onWindowResize();
    noScroll.on();
  }

  onClose() {
    noScroll.off();
  }

  onUpdateContentHeight = (contentHeight) => {
    this._contentHeight = contentHeight;
    this.onWindowResize();
  };

  onUpdateScroll = () => {};

  onWindowResize = () => {
    const verticalMargin = getVerticalMargin();
    const windowHeight = height(window);
    // For mobile (or short) browsers there will be no margin below the modal
    // to maximize vertical real estate
    const maxHeight = windowHeight - verticalMargin * (windowHeight < 800 ? 1 : 2);
    const modalHeight = Math.min(
      Math.max(this._contentHeight, this.instance.props.minHeight),
      maxHeight
    );
    this.instance.modal.style.top = verticalMargin;
    this.instance.modalContent.style.height = toPx(modalHeight);
  };
}

/**
 * For iOS browsers, which do not support iframe overflow scrolling, we:
 *
 * - use "absolute" positioning
 * - we scroll the page rather than the modal content
 */
class PageScrollingLayoutManager {
  constructor(instance) {
    this.instance = instance;
  }

  init = () => {
    this.instance.modal.style.position = 'absolute';
    this.instance.modalContent.style.height = 'auto';
  };

  onOpen = () => {
    this.instance.modal.style.top = toPx(scrollTop(window) + getVerticalMargin());
  };

  onClose = () => {};

  onUpdateContentHeight = (contentHeight) => {
    this.instance.iframe.update({ height: Math.max(contentHeight, this.instance.props.minHeight) });
  };

  onUpdateScroll = (elementTop) => {
    scrollToPosition(elementTop);
  };
}

export default class Modal {
  static defaultProps = {
    minHeight: 365,
  };

  constructor(props: ModalProps = {}) {
    this.props = { ...this.constructor.defaultProps, ...props };
    if (compat.overflowScrolling) {
      this._layoutManager = new PageScrollingLayoutManager(this);
    } else {
      this._layoutManager = new ModalScrollingLayoutManager(this);
    }
    this._initialRender();
    this._transition = new Transition(this.element, { enterDuration: 250, exitDuration: 250 });
    document.addEventListener(
      'click',
      filterEventHandler('[data-localmed-dismiss="modal"]', () => this.close())
    );
    document.addEventListener('focusin', this._onGlobalFocusIn, false);
    document.addEventListener('keyup', this._onKeyup, false);
    if (this._layoutManager.onWindowResize) {
      window.addEventListener('resize', throttle(this._layoutManager.onWindowResize, 50), false);
    }
    this._layoutManager.init();
  }

  _initialRender() {
    this.element = parseHtml(`
      <div class="localmed-modal-wrapper" aria-hidden="true">
        <div class="localmed-modal-overlay" tabindex="-1" data-localmed-dismiss="modal"></div>
        <div class="localmed-modal" role="dialog">
          <button type="button" class="localmed-modal-close" aria-label="Close" data-localmed-dismiss="modal">
            <svg viewBox="0 0 24 24" width="0.8em" height="0.8em" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1">
              <line x1="18" y1="6" x2="6" y2="18"></line>
              <line x1="6" y1="6" x2="18" y2="18"></line>
            </svg>
          </button>
          <div class="localmed-modal-content loading-localmed"></div>
        </div>
      </div>
    `);
    this.modal = this.element.querySelector('.localmed-modal');
    this.modalContent = this.element.querySelector('.localmed-modal-content');
    this.iframe = new Iframe({
      src: this.props.src,
      onUpdateContentHeight: this._layoutManager.onUpdateContentHeight,
      onUpdateScroll: this._layoutManager.onUpdateScroll,
      onLoad: this._onIframeLoad,
    });
    this.modalContent.appendChild(this.iframe.element);
    return this.element;
  }

  open = () => {
    if (this._isOpen) return;
    this._layoutManager.onOpen();

    this._isOpen = true;
    this.element.removeAttribute('aria-hidden');
    this._transition.enter();
    this._focusInModal();
  };

  close = () => {
    if (!this._isOpen) return;

    this._isOpen = false;
    this._returnFocus();
    this.element.setAttribute('aria-hidden', 'true');
    this._transition.exit();
    this._layoutManager.onClose();
  };

  _onGlobalFocusIn = (event) => {
    // Trap focus in modal
    if (this._isOpen && !this.element.contains(event.target)) {
      this.iframe.element.focus();
    }
  };

  _focusInModal() {
    if (!this._iframeLoaded) return;

    this.previouslyFocusedElement = activeElement();
    this.iframe.element.focus();
  }

  _returnFocus() {
    if (this.previouslyFocusedElement) {
      this.previouslyFocusedElement.focus();
      this.previouslyFocusedElement = null;
    }
  }

  _onKeyup = (event) => {
    if (event.keyCode === 27) {
      this.close();
    }
  };

  _onIframeLoad = () => {
    this._iframeLoaded = true;
    removeClass(this.modalContent, 'loading-localmed');
    this._focusInModal();
  };
}
