/* global window */

import Promise from 'core-js/es6/promise';

// A very simple $.ajax replacement. We don't need a complete fetch polyfill
// for our two API calls.
function ajax(method, uri, data = null) {
  return new Promise((resolve, reject) => {
    const request = new window.XMLHttpRequest();
    request.open(method, uri, true);
    request.setRequestHeader('Accept', 'application/json');

    request.onload = function onLoad() {
      if (request.status > 299) {
        reject(new Error(`Request failed with status code: ${request.status}`));
        return;
      }
      try {
        resolve(JSON.parse(request.responseText));
      } catch (error) {
        reject(error);
      }
    };

    request.onerror = function onError() {
      reject(new Error('Request failed'));
    };

    if (data) {
      request.setRequestHeader('Content-Type', 'application/json');
    }
    request.send(data);
  });
}

export function getJSON(uri) {
  return ajax('GET', uri);
}

export function postJSON(uri, data) {
  return ajax('POST', uri, JSON.stringify(data));
}
