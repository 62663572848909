/* eslint-disable */

// A very simple throttling function.
//
//   $(window).on('resize', throttle(function(event) {
//     // only execute this function 250ms after the final resize event
//   }, 250));
const throttle = function(callback, time = 100) {
  let timeout = null;
  return function(...args) {
    clearTimeout(timeout);
    return (timeout = setTimeout(callback, time));
  };
};

export default throttle;
