/* global document */

// Handles loading necessary assets on the host website.

const headElement = document.getElementsByTagName('head')[0] || document.documentElement;

// eslint-disable-next-line import/prefer-default-export
export function loadStylesheet(url) {
  if (document.createStyleSheet != null) {
    return document.createStyleSheet(url);
  }
  const link = document.createElement('link');
  link.rel = 'stylesheet';
  link.href = url;
  return headElement.appendChild(link);
}
