/* global window */
/*!
LocalMed Widget v{{VERSION}}
*/

import 'core-js/es5';
import 'core-js/fn/object/assign';
import lm from './widget/globals';
import { configure, init, renderAll, createAll } from './widget/sdk';
import * as modals from './widget/factories/modals';
import * as buttons from './widget/factories/buttons';
import * as embeds from './widget/factories/embeds';
import * as tabs from './widget/factories/tabs';

const lmapi = {
  configure,
  renderAll,
  createAll,
  modal: modals,
  button: buttons,
  embed: embeds,
  tab: tabs,
  version: lm.version,
};
window.lmapi = lmapi;
export default lmapi;
init();
