import removeClass from 'dom-helpers/removeClass';

import { parseHtml } from '../utils/dom';
import { scrollToPosition } from '../utils/scrolling';
import Iframe from './iframe';

type EmbedProps = {
  src: string,
  name?: string,
  minHeight?: number,
};

export default class Embed {
  static defaultProps = {
    minHeight: 365,
  };

  constructor(props: EmbedProps = {}) {
    this.props = { ...this.constructor.defaultProps, ...props };
    this._initialRender();
    this._onUpdateContentHeight();
  }

  _initialRender() {
    this.element = parseHtml(`<div class="localmed-embed loading-localmed"></div>`);
    this.iframe = new Iframe({
      src: this.props.src,
      name: this.props.name,
      onUpdateContentHeight: this._onUpdateContentHeight,
      onUpdateScroll: scrollToPosition,
      onLoad: this._onIframeLoad,
    });
    this.element.appendChild(this.iframe.element);
    return this.element;
  }

  _onUpdateContentHeight = contentHeight => {
    this.iframe.update({ height: Math.max(contentHeight || 0, this.props.minHeight) });
  };

  _onIframeLoad = () => {
    removeClass(this.element, 'loading-localmed');
  };
}
