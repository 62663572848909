// Simplifies cross-browser iFrame messaging by serializing the payload as JSON.

export function postMessage(element, message, targetOrigin = '*') {
  if (typeof message !== 'string') {
    // eslint-disable-next-line no-param-reassign
    message = JSON.stringify(message);
  }
  if (element && typeof element.postMessage === 'function') {
    element.postMessage(message, targetOrigin);
  }
}

export function onMessage(element, callback) {
  element.addEventListener('message', event => {
    let data = {};
    if (typeof event.data === 'string') {
      try {
        data = JSON.parse(event.data);
      } catch (e) {} // eslint-disable-line no-empty
    }
    callback(data, event);
  });
}
