/* global window */
/* eslint-disable no-use-before-define */
// Setup the framework in 4 phases:
//
//   1. Configure the SDK
//   2. Load stylesheet
//   4. Initiate data attribute APIs
//   5. The DOM is now ready, call the factory methods.

import lm from './globals';
import * as factories from './factories';
import { loadStylesheet } from './utils/loading';
import { renderAll as renderAllButtons } from './factories/buttons';
import { initDataApi as initModalDataApi, renderAll as renderAllModals } from './factories/modals';
import { renderAll as renderAllEmbeds } from './factories/embeds';
import { onReady } from './utils/dom';

export function init() {
  // (1) Configure the SDK
  configure(window.lmconfig);
  // (2) Load stylesheet
  loadStylesheet(`${lm.options.baseUrl}/assets/web/css/widget.css`);
  // (3) Initiate data attribute APIs
  initModalDataApi();
  // (4) Call the factory methods
  onReady(() => {
    createAll(window.lmq);
    renderAll();
  });
}

// Configure the SDK. Use this method instead of modifying `options`
// directly.
export function configure(config) {
  if (typeof config !== 'object') {
    return lm.options;
  }
  Object.keys(config).forEach(option => {
    const value = config[option];
    if (Object.prototype.hasOwnProperty.call(lm.options, option)) {
      lm.options[option] = value;
    }
  });
  return lm.options;
}

// Calls all factory methods in the given queue. The queue should be a plain
// array where each item is factory method signature:
//
//   > sdk.createAll([
//     ['createTab', {href: 'https://www.localmed.com/'}]
//   ]);
//   // This calls factories.createTab({href: 'https://www.localmed.com/'})
export function createAll(queue) {
  if (queue == null) {
    return queue;
  }
  queue.forEach(([factoryName, ...args]) => {
    const factory = factories[factoryName];
    if (typeof factory === 'function') {
      factory.apply(lm.factories, args);
    }
  });
  return queue;
}

// Renders any embedded content on the host's page.
export function renderAll() {
  renderAllModals();
  renderAllButtons();
  renderAllEmbeds();
}
