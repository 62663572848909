/* global document */

import closest from 'dom-helpers/closest';
import filterEventHandler from 'dom-helpers/filterEventHandler';
import querySelectorAll from 'dom-helpers/querySelectorAll';

import Modal from '../components/modal';
import compat from '../utils/compat';
import { trackImpressionFromElement } from '../utils/tracking';
import { getWidgetUriFromElement } from '../utils/uris';

// Creates a new Modal with the given content. The Modal is automatically
// appended to the <body> element unless another container is given.
export function create(uri, container = null) {
  const modal = new Modal({ src: uri });
  if (container) {
    container.appendChild(modal.element);
  }
  return modal;
}

const modalsCache = {};

// Get the Modal with <iframe> content matching the given uri, otherwise
// create a new one.
export function getOrCreate(uri, container = null) {
  if (modalsCache[uri] == null) {
    modalsCache[uri] = create(uri, container);
  }
  return modalsCache[uri];
}

const toggleModalSelector = '[data-localmed-toggle="modal"]';

// Initiate the data api for all elements with a `data-localmed-toggle="modal"`
// attribute.
export function initDataApi(container = document.body) {
  return container.addEventListener(
    'click',
    filterEventHandler(toggleModalSelector, event => {
      if (compat.ieCompatibilityMode) return;

      const toggle = closest(event.target, toggleModalSelector);
      if (toggle == null) return;

      event.preventDefault();
      trackImpressionFromElement(toggle);
      getWidgetUriFromElement(toggle)
        .then(widgetUri => getOrCreate(widgetUri, container))
        .then(modal => modal.open());
    })
  );
}

export function render(placeholder) {
  return trackImpressionFromElement(placeholder);
}

export function renderAll(container = document.body) {
  const modalPlaceholders = querySelectorAll(container, toggleModalSelector);
  return Promise.all(modalPlaceholders.map(placeholder => render(placeholder)));
}
