// @flow
/* global HTMLElement */

import addClass from 'dom-helpers/addClass';
import removeClass from 'dom-helpers/removeClass';

type TransitionOptions = {
  enterDuration: number,
  exitDuration: number,
};

const CLASS_NAMES = {
  enter: 'localmed-enter',
  enterActive: 'localmed-enter-active',
  enterDone: 'localmed-enter-done',
  exit: 'localmed-exit',
  exitActive: 'localmed-exit-active',
  exitDone: 'localmed-exit-done',
};

function removeClasses(element: HTMLElement, classNames: Array<string>) {
  classNames.forEach(className => removeClass(element, className));
}

function reflowAndAddClass(element: HTMLElement, className: string) {
  // This is to force a repaint, which is necessary in order
  // to transition styles when adding a class name.
  // eslint-disable-next-line no-unused-expressions
  element && element.scrollTop;
  addClass(element, className);
}

// A simple transition manager modeled after react-transition-group's CSSTransition:
// https://github.com/reactjs/react-transition-group/blob/master/src/CSSTransition.js
export default class Transition {
  element: HTMLElement;
  options: TransitionOptions;

  constructor(element, options: TransitionOptions) {
    this.element = element;
    this.options = options;
  }

  enter() {
    clearTimeout(this._animationTimeout);
    removeClasses(this.element, [CLASS_NAMES.exit, CLASS_NAMES.exitActive, CLASS_NAMES.exitDone]);
    addClass(this.element, CLASS_NAMES.enter);
    reflowAndAddClass(this.element, CLASS_NAMES.enterActive);
    this._animationTimeout = setTimeout(() => {
      removeClass(this.element, CLASS_NAMES.enter);
      addClass(this.element, CLASS_NAMES.enterDone);
    }, this.options.enterDuration);
  }

  exit() {
    clearTimeout(this._animationTimeout);
    removeClasses(this.element, [
      CLASS_NAMES.enter,
      CLASS_NAMES.enterActive,
      CLASS_NAMES.enterDone,
    ]);
    addClass(this.element, CLASS_NAMES.exit);
    reflowAndAddClass(this.element, CLASS_NAMES.exitActive);
    this._animationTimeout = setTimeout(() => {
      removeClass(this.element, CLASS_NAMES.exit);
      addClass(this.element, CLASS_NAMES.exitDone);
    }, this.options.exitDuration);
  }
}
