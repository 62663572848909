/* global document, window */

import scrollTop from 'dom-helpers/scrollTop';

import lm from '../globals';
import { parseHtml, toPx } from '../utils/dom';
import { onMessage, postMessage } from '../utils/messaging';

function generateUniqueName() {
  const randomId = Math.random()
    .toString(36)
    .substring(2, 10);
  return `localmed-iframe-${randomId}`;
}

type IframeProps = {
  src: string,
  name?: string,
  onUpdateContentHeight?: (contentHeight: number) => void,
  onUpdateScroll?: (elementTop: number) => void,
  onLoad?: () => void,
};

export default class Iframe {
  constructor(props: IframeProps = {}) {
    this.props = props;
    if (this.props.name == null) {
      this.props.name = generateUniqueName();
    }
    this._initialRender();
    this.element.addEventListener('load', this._onLoad, false);
    onMessage(window, this._onMessage);
  }

  _initialRender() {
    this.element = parseHtml(`
      <iframe
        class="localmed-iframe"
        name="${this.props.name}"
        src="${this.props.src}"
        frameborder="0"
      />
    `);
  }

  _onMessage = ({ event, iframeName, ...data }) => {
    if (iframeName !== this.element.name) return;

    const { onUpdateContentHeight, onUpdateScroll } = this.props;
    if (event === 'ping') {
      postMessage(this.element.contentWindow, { event: 'pong', version: lm.version });
    } else if (event === 'updateContentHeight') {
      if (onUpdateContentHeight) onUpdateContentHeight(data.contentHeight);
    } else if (event === 'updateScroll') {
      if (onUpdateScroll && document.activeElement === this.element) {
        const iframeTop = this.element.getBoundingClientRect().top + scrollTop(window);
        onUpdateScroll(iframeTop + data.offsetTop);
      }
    }
  };

  _onLoad = () => {
    if (this.props.onLoad) {
      this.props.onLoad();
    }
  };

  update({ height }) {
    if (height == null) {
      this.element.style.height = 'auto';
      this.element.scrolling = 'yes';
    } else {
      this.element.style.height = toPx(height);
      this.element.scrolling = 'no';
    }
  }
}
