// @flow
/* global document, HTMLElement, Window */

export type HTMLElementOrWindow = HTMLElement | Window;

// http://youmightnotneedjquery.com/#parse_html
export function parseHtml(htmlString: string): ?HTMLElement {
  const tmp = document.implementation.createHTMLDocument('Parse HTML Document');
  tmp.body.innerHTML = htmlString;
  return tmp.body.children[0];
}

export function replaceWith(currentElement: HTMLElement, newElement: HTMLElement): HTMLElement {
  const parent = currentElement.parentNode;
  if (!parent) return currentElement;

  parent.replaceChild(newElement, currentElement);
  return newElement;
}

function isNumber(n: any): boolean {
  return typeof n === 'number' || !isNaN(n);
}

export function toPx(n: any): any {
  return isNumber(n) && n !== 0 ? `${n}px` : n;
}

export function onReady(callback: () => void): void {
  if (document.readyState !== 'loading') {
    callback();
    return;
  }
  document.addEventListener('DOMContentLoaded', callback);
}
