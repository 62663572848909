import lm from '../globals';
import { postJSON } from './api';

const trackedWidgets = {};

export function trackImpression(href) {
  if (!href) return false;

  const match = href.match(/\/widgets\/([\w-]+)\/?/);
  if (!match) return false;

  const widgetId = match[1];
  if (trackedWidgets[widgetId]) return false;

  postJSON(`${lm.options.baseUrl}/widgets/sessions/events/`, { widgetId, type: 'IMPRESSION' });
  trackedWidgets[widgetId] = true;
  return true;
}

export function trackImpressionFromElement(element) {
  const href = element.getAttribute('href') || element.getAttribute('data-href');
  trackImpression(href);
  return element;
}
