/* global document */

// Factories can be called by populating the `lmq` array on the host website,
// similar to how Google Analytics works. Once the DOM is ready, any factory
// methods queued on the host website will be executed - creating all the
// necessary widgets.

import { create as _createTab } from './factories/tabs';

// eslint-disable-next-line import/prefer-default-export
export function createTab(options) {
  return _createTab(options, document.body);
}
