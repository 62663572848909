// The Tab plugin displays a single LocalMed style button fixed to the side
// of the host's page that displays a Modal when clicked.

import lm from '../globals';
import { parseHtml } from '../utils/dom';
import compat from '../utils/compat';
import { trackImpression } from '../utils/tracking';
import { getWidgetUri } from '../utils/uris';

// eslint-disable-next-line import/prefer-default-export
export function create({ href, ...options } = {}, container = null) {
  // eslint-disable-next-line no-param-reassign
  options = {
    baseUrl: lm.options.baseUrl,
    text: 'Schedule Online',
    ...options,
  };
  const positionClass = options.position === 'right' ? ' right-localmed-tab' : '';
  trackImpression(href);
  return getWidgetUri(href, options).then(uri => {
    const element = parseHtml(`
      <a href="${uri}" target="_blank" class="localmed-tab${
      positionClass
    }" data-localmed-toggle="modal">
        ${options.text}
      </a>
    `);
    if (compat.cssTransforms) {
      const image = parseHtml(
        `
        <img src="${
          options.baseUrl
        }/assets/web/images/localmed-icon-sm.png" alt="LocalMed Icon" width="26" height="26" />
      `
      );
      element.appendChild(image);
    }
    if (container) {
      container.appendChild(element);
    }
    return element;
  });
}
