/* global document */

import querySelectorAll from 'dom-helpers/querySelectorAll';

import Embed from '../components/embed';
import { replaceWith } from '../utils/dom';
import { trackImpression, trackImpressionFromElement } from '../utils/tracking';
import { getWidgetUri, getWidgetUriFromElement } from '../utils/uris';

export function create({ href, ...options }, container = null) {
  trackImpression(href);
  return getWidgetUri(href, options).then(widgetUri => {
    const embed = new Embed({ ...options, src: widgetUri });
    if (container) {
      container.appendChild(embed.element);
    }
    return embed;
  });
}

export function render(placeholder, options = {}) {
  trackImpressionFromElement(placeholder);
  return getWidgetUriFromElement(placeholder, options)
    .then(widgetUri => new Embed({ ...options, src: widgetUri }))
    .then(embed => {
      replaceWith(placeholder, embed.element);
      return embed;
    });
}

export function renderAll(container = document.body) {
  const embedPlaceholders = querySelectorAll(container, '[data-localmed-embed]');
  return Promise.all(embedPlaceholders.map(placeholder => render(placeholder)));
}
