/* global console window */
/* eslint-disable no-console, no-param-reassign */

import Promise from 'core-js/es6/promise';
import urlParse from 'url-parse';

import { getJSON } from './api';

// -----------------------------------------------------------------------------
// params
// -----------------------------------------------------------------------------

const ALLOWED_PARAMS = [
  'office_id',
  'specialty_id',
  'insurance_issuer_id',
  'patient_status_id',
  'reason_for_visit_id',
  'organization_id',
  'chrome',
  'scheduling',
  'utm_campaign',
  'utm_source',
  'utm_medium',
  'utm_content',
  'utm_term',
  '_ga',
];

const DEFAULT_PARAMS = {
  chrome: '',
  scheduling: '',
  organization_id: '',
};

function getAllowedParams(params) {
  return Object.keys(params).reduce((acc, key) => {
    if (ALLOWED_PARAMS.indexOf(key) !== -1) {
      acc[key] = params[key];
    }
    return acc;
  }, {});
}

// Ensure the parameters are not encoded twice
function decodeParams(params = {}) {
  return Object.keys(params).reduce((acc, key) => {
    const value = params[key];
    if (typeof value === 'string') {
      try {
        acc[key] = decodeURIComponent(value);
      } catch (_) {
        acc[key] = value;
      }
    } else {
      acc[key] = value;
    }
    return acc;
  }, {});
}

function mergeParams(uri, params) {
  const url = urlParse(uri, true);
  url.set('query', { ...url.query, ...params });
  return url.toString();
}

function mergeParamsFromWidgetUri(widgetUri, canonicalUri) {
  const url = urlParse(widgetUri, true);
  return mergeParams(canonicalUri, getAllowedParams(url.query));
}

// -----------------------------------------------------------------------------
// getTrackingUri
// -----------------------------------------------------------------------------

// Adds the `_ga` linker param if available, for affiliate tracking.
//
// See: https://developers.google.com/analytics/devguides/collection/analyticsjs/linker#linkerparam
export function getTrackingUri(uri, { timeout = 3000 } = {}) {
  if (typeof window.ga !== 'function') {
    return Promise.resolve(uri);
  }
  return new Promise(resolve => {
    // If Google Analytics takes too long to load, we'll just use the existing URI.
    setTimeout(() => resolve(uri), timeout);

    window.ga(tracker => {
      try {
        const linkerParam = tracker.get('linkerParam');
        const [key, value] = linkerParam.split('=');
        resolve(mergeParams(uri, { [key]: value }));
      } catch (error) {
        // When the host uses Google Tag Manager, the tracker is `undefined`. This method
        // of getting the linker param will not work, and we'll just use the existing URI.
        resolve(uri);
      }
    });
  });
}

// -----------------------------------------------------------------------------
// getWidgetUri
// -----------------------------------------------------------------------------

function getWidgetUriFromApi(widgetUri) {
  return getJSON(widgetUri)
    .then(data => {
      if (!data.canonicalUrl) {
        if (console) {
          console.error('Widget response is missing `canonicalUrl`');
        }
        return widgetUri;
      }
      return mergeParamsFromWidgetUri(widgetUri, data.canonicalUrl);
    })
    .catch(error => {
      if (console) {
        console.error(error);
      }
      return widgetUri;
    });
}

export function getWidgetUriFromExistingUri(uri, params = {}) {
  const url = urlParse(uri, true);
  const path = url.pathname.replace(/\/(widget\/?)?$/, '');
  url.set('pathname', `${path}/widget/`);
  url.set('query', {
    ...DEFAULT_PARAMS,
    ...decodeParams(url.query),
    ...getAllowedParams(params),
  });
  return Promise.resolve(url.toString());
}

// Builds a Widget URI by appending to /widget/ the path and appending
// any accepeted params to the query string.
export function getWidgetUri(uri, { parseWidgetUri, applyTracking, ...params } = {}) {
  let getUri;
  // Avoid constructing the widget URI multiple times, which would eventually
  // add the `organization_id` param to a URI from the widgets API.
  if (parseWidgetUri === false || /\/(practices|offices|providers)\/(.*?)\/widget\//.test(uri)) {
    getUri = Promise.resolve(uri);
  } else if (/\/widgets\/(.*?)\/?/.test(uri)) {
    getUri = getWidgetUriFromApi(uri);
  } else {
    getUri = getWidgetUriFromExistingUri(uri, params);
  }
  return getUri.then(widgetUri => {
    if (applyTracking === false) return widgetUri;

    return getTrackingUri(widgetUri);
  });
}

// -----------------------------------------------------------------------------
// getWidgetUriFromElement
// -----------------------------------------------------------------------------

const DATA_ATTR_PARAM_MAPPING = {
  'data-office-id': 'office_id',
  'data-specialty-id': 'specialty_id',
  'data-insurance-issuer-id': 'insurance_issuer_id',
  'data-patient-status-id': 'patient_status_id',
  'data-reason-for-visit-id': 'reason_for_visit_id',
  'data-chrome': 'chrome',
  'data-scheduling': 'scheduling',
  'data-organization-id': 'organization_id',
  'data-utm-campaign': 'utm_campaign',
  'data-utm-source': 'utm_source',
  'data-utm-medium': 'utm_medium',
  'data-utm-content': 'utm_content',
  'data-utm-term': 'utm_term',
};

function getParamsFromDataAttributes(element) {
  return Object.keys(DATA_ATTR_PARAM_MAPPING).reduce((acc, key) => {
    const param = DATA_ATTR_PARAM_MAPPING[key];
    const value = element.getAttribute(key);
    if (value != null) {
      acc[param] = value;
    }
    return acc;
  }, {});
}

// Extract a Widget URI from the given element's 'href' or 'data-href'
// attributes and append any params added via the data attribute API.
export function getWidgetUriFromElement(element, { href, ...params } = {}) {
  href = href || element.getAttribute('href') || element.getAttribute('data-href');
  if (!href) {
    return Promise.reject(new Error('Element does not contain `href` or `data-href` attributes.'));
  }
  params = { ...getParamsFromDataAttributes(element), ...params };
  return getWidgetUri(href, params);
}
