/* global document, navigator */

// A mini, self-contained version of Modernizr that runs only
// the feature tests we need for the JavaScript SDK. All classes added to
// the <html> element are prefixed with "localmed-".

const doc = document.documentElement;
const styles = document.createElement('featuretests').style;
const prefixes = ['Webkit', 'Moz', 'O', 'ms'];

const features = {
  addTest(feature, test) {
    if (this[feature] == null) {
      if (typeof test === 'function') {
        // eslint-disable-next-line no-param-reassign
        test = test();
      }
      doc.className += ` ${test ? '' : 'no-'}localmed-${feature.toLowerCase()}`;
      this[feature] = test;
    }
    return this;
  },

  addTests(tests) {
    Object.keys(tests).forEach(feature => {
      const test = tests[feature];
      this.addTest(feature, test);
    });
    return this;
  },

  testProps(props) {
    return props.some(prop => styles[prop] != null);
  },

  testPropsAll(prop) {
    const ucProp = prop.charAt(0).toUpperCase() + prop.slice(1);
    // eslint-disable-next-line prefer-template
    const props = (prop + ' ' + prefixes.join(ucProp + ' ') + ucProp).split(' ');
    return this.testProps(props);
  },
};

features.addTests({
  cssTransforms() {
    return features.testPropsAll('transform');
  },
  overflowScrolling() {
    return features.testPropsAll('overflowScrolling');
  },
  ieCompatibilityMode() {
    const { userAgent } = navigator;
    return (
      (userAgent.indexOf('MSIE 7.0') > -1 || userAgent.indexOf('MSIE 8.0') > -1) &&
      userAgent.indexOf('Trident') > -1
    );
  },
  smoothScrolling() {
    let supports = false;
    try {
      const div = document.createElement('div');
      div.scrollTo({
        top: 0,
        get behavior() {
          supports = true;
          return 'smooth';
        },
      });
    } catch (err) {
      // swallow error
    }
    return supports;
  },
});

export default features;
