/* global document */

// The Button plugin turns regular links on the host's page into LocalMed
// style buttons that trigger Modals when clicked.

import Promise from 'core-js/es6/promise';
import querySelectorAll from 'dom-helpers/querySelectorAll';

import lm from '../globals';
import { parseHtml, replaceWith } from '../utils/dom';
import { trackImpression, trackImpressionFromElement } from '../utils/tracking';
import { getWidgetUri, getWidgetUriFromElement } from '../utils/uris';

function createButton({ href, ...options }, container = null) {
  // eslint-disable-next-line no-param-reassign
  options = {
    baseUrl: lm.options.baseUrl,
    text: 'Schedule Online',
    ...options,
  };
  const element = parseHtml(`
    <a href="${href}" target="_blank" class="localmed-button" data-localmed-toggle="modal">
      ${options.text}
      <img src="${
        options.baseUrl
      }/assets/web/images/localmed-icon-tiny.png" alt="LocalMed Icon" width="16" height="16" />
    </a>
  `);
  if (container) {
    container.appendChild(element);
  }
  return element;
}

export function create({ href, ...options }, container = null) {
  trackImpression(href);
  return getWidgetUri(href, options).then(widgetUri =>
    createButton({ ...options, href: widgetUri }, container)
  );
}

export function render(placeholder, options = {}) {
  // eslint-disable-next-line no-param-reassign
  options = { text: placeholder.textContent, ...options };
  trackImpressionFromElement(placeholder);
  return getWidgetUriFromElement(placeholder, options)
    .then(widgetUri => createButton({ ...options, href: widgetUri }))
    .then(element => replaceWith(placeholder, element));
}

export function renderAll(container = document) {
  const buttonPlaceholders = querySelectorAll(container, '[data-localmed-button]');
  return Promise.all(buttonPlaceholders.map(placeholder => render(placeholder)));
}
