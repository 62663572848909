// @flow
/* global window */

import height from 'dom-helpers/height';
import scrollTop from 'dom-helpers/scrollTop';
import scrollLeft from 'dom-helpers/scrollLeft';

import compat from './compat';

export const DEFAULT_VISIBILITY_THRESHOLD = 0.6;
export const DEFAULT_SCROLL_OFFSET = -24;
export const DEFAULT_SCROLL_BEHAVIOR = 'smooth';

export type ScrollBehavior = 'instant' | 'smooth' | 'auto';

export type ScrollToOptions = {
  top?: number,
  left?: number,
  behavior?: ScrollBehavior,
};

/**
 * This will use the native `window.scroll` method to scroll to a particular location using
 * animation if possible.
 */
export function scrollTo(
  {
    top = scrollTop(window),
    left = scrollLeft(window),
    behavior = DEFAULT_SCROLL_BEHAVIOR,
  }: ScrollToOptions = {}
) {
  if (typeof window === 'undefined') return;

  if (!compat.smoothScrolling) {
    window.scroll(left, top);
    return;
  }

  try {
    // Use options object, if supported, to get a smooth scroll
    window.scroll({ left, top, behavior });
  } catch (error) {
    // otherwise fallback to setting the scroll position
    window.scroll(left, top);
  }
}

export function isPositionVisible(
  elementTop: number,
  visibilityThreshold: number = DEFAULT_VISIBILITY_THRESHOLD
): boolean {
  const targetTop = scrollTop(window);
  const targetBottom = targetTop + height(window) * visibilityThreshold;
  return elementTop > targetTop && elementTop < targetBottom;
}

export type ScrollToPositionOptions = {
  always?: boolean,
  visibilityThreshold?: number,
  offset?: number,
  behavior?: ScrollBehavior,
};

export function scrollToPosition(
  elementTop: Number,
  {
    always,
    visibilityThreshold = DEFAULT_VISIBILITY_THRESHOLD,
    offset = DEFAULT_SCROLL_OFFSET,
    behavior = DEFAULT_SCROLL_BEHAVIOR,
  }: ScrollToPositionOptions = {}
): void {
  if (always || !isPositionVisible(elementTop, visibilityThreshold)) {
    scrollTo({ top: elementTop + offset, behavior });
  }
}
